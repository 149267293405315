<template>
  <div class="list" :class="{ hasnews: news && news.isShow }">
    <div class="head">
      <div class="logo dis_flex_center"><img src="~@/assets/images/melogo2.png" alt="" /></div>
      <div class="head-r">
        <ul class="dis_flex">
          <li class="user ">
            <el-dropdown trigger="click">
              <div class="el-dropdown-link dis_flex">
                <span v-if="userInfo">{{ userInfo.name }}</span
                ><i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <el-dropdown-menu class="listdropdown" slot="dropdown">
                <el-dropdown-item><a :href="changepwd" target="_blank">修改密码</a></el-dropdown-item>
                <el-dropdown-item @click.native="signout"><a>退出登录</a></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
    </div>
    <news class="news_x" v-if="paging.tableData" system="me"></news>
    <div class="list-table" :class="{ hasnews: news && news.isShow && news.detailUrl }">
      <div class="table-box">
        <div class="table-top">
          <el-input
            class="filter"
            v-model="keyword"
            prefix-icon="el-icon-search"
            clearable
            size="small"
            @input="filterFunc"
            placeholder="请输入关键字"
          ></el-input>
        </div>
        <el-table v-if="paging.tableData.length > 0" :data="paging.tableData" @sort-change="sortChange" border size="small">
          <el-table-column prop="mallID" sortable label="Mall ID" min-width="300"></el-table-column>
          <el-table-column prop="mallName" sortable label="商场名称" min-width="300"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="visit(scope.row.mallID)">访问</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="paging.index"
          :page-sizes="paging.sizes"
          :page-size="paging.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="paging.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import BI_config from '@/utils/config'
import news from '@/components/news.vue'
import { getMallList, getMpUserInfo } from '@/api/apiV2_dashboard'
import { mapState } from 'vuex'
import { removeToken } from '@/utils/auth'
export default {
  data() {
    let vm = this
    return {
      userInfo: null,
      authInfo: null,
      changepwd: BI_config.changePwd,
      listdata: [],
      filterData: [],
      paging: {
        sizes: [20, 40, 60],
        size: 0,
        index: 1,
        get total() {
          return vm.filterData.length
        },
        get tableData() {
          return vm.filterData.slice((vm.paging.index - 1) * vm.paging.size, vm.paging.index * vm.paging.size)
        },
      },
      keyword: '',
    }
  },
  components: {
    news,
  },
  computed: {
    ...mapState({
      projectID: state => state.projectID,
      news: state => state.news,
    }),
  },
  methods: {
    signout() {
      removeToken()
      location.reload()
    },

    visit(mallid) {
      let { href } = this.$router.resolve({ path: BI_config.dashboardProxy + '/me/' + mallid })
      window.open(href, '_blank')
    },

    handleSizeChange(size) {
      this.paging.size = size
      this.paging.index = 1
    },

    handleCurrentChange(index) {
      this.paging.index = index
    },

    sortChange(params) {
      let { prop, order } = params
      let sortdata = JSON.parse(JSON.stringify(this.listdata))
      if (order !== null) {
        sortdata.sort(function(a, b) {
          let p1 = a[prop]
          let p2 = b[prop]
          if (params.prop == 'mallID') {
            p1 = p1[0]
            p2 = p2[0]
          }
          if (order === 'ascending') {
            if (p1 > p2) {
              return 1
            } else {
              return -1
            }
          } else if (order === 'descending') {
            if (p1 < p2) {
              return 1
            } else {
              return -1
            }
          }
        })
      } else {
        sortdata = JSON.parse(JSON.stringify(this.listdata))
      }

      this.listdata = sortdata
      this.paging.index = 1
      this.onSearch()
    },

    filterFunc: function() {
      this.paging.index = 1
      this.onSearch()
    },

    onSearch: function() {
      if (this.keyword) {
        this.keyword = this.keyword.trim()
        this.filterData = this.listdata.filter(x => (x.mallName && x.mallName.toLowerCase().includes(this.keyword.toLowerCase())) || x.mallID == this.keyword)
      } else {
        this.filterData = this.listdata
      }
    },
  },
  async created() {
    this.paging.size = this.paging.sizes[0]
    this.$loading({ lock: true, spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' })
    let userRes = await getMpUserInfo()
    this.userInfo = userRes.data.user
    this.authInfo = userRes.data.authInfo
    let mallRes = await getMallList()
    this.listdata = mallRes.data
    this.onSearch()
    this.$loading({ lock: false }).close()
  },
  mounted() {},
}
</script>

<style lang="scss">
.list {
  min-height: 100%;
  background: #f1f4fa;
  padding-top: 74px;
  &.hasnews {
    padding-top: 127px;
  }
  .head {
    width: 100%;
    height: 60px;
    background: #fff;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.12);
    position: fixed;
    z-index: 99;
    top: 0;
    .logo {
      float: left;
      width: 250px;
      height: 60px;
      img {
        width: 133px;
      }
    }
    .head-r {
      float: right;
      height: 60px;
      padding-right: 15px;
      ul {
        height: 100%;
        li {
          margin: 0 10px;
          font-size: 14px;
          cursor: pointer;
          &.user {
            img {
              width: 24px;
              height: 24px;
              border-radius: 12px;
              background: #a6dbff;
              margin-right: 5px;
            }
          }
          .el-dropdown {
          }
          > a {
            color: #333;
          }
          .iconfont {
            font-size: 18px;
          }
        }
      }
    }
  }
  .list-table {
    &.hasnews {
      top: 127px;
      position: absolute;
      height: calc(100% - 127px);

      transition: 0.3s ease-in-out;
    }
    top: 74px;
    position: absolute;
    height: calc(100% - 74px);
    width: calc(100% - 24px);
    overflow-y: auto;
    background: #ffff;
    margin: 0 15px;
    padding: 15px;
    .table-top {
      text-align: right;
      .el-select {
        margin-right: 15px;
      }
      .filter {
        width: 200px;
        margin-bottom: 10px;
      }
    }

    .table-box {
      overflow-y: auto;
      .el-table {
        color: #333;
        .el-button {
          padding: 0;
        }
        .el-table__header {
          tr {
            th {
              color: #333;
            }
          }
        }
        .allmallIds {
          span {
            white-space: nowrap;
          }
        }
      }
      .el-pagination {
        margin-top: 10px;
      }
    }
  }
  .news_x {
    width: calc(100% - 30px);
  }
}

.el-dropdown-menu.listdropdown {
  .el-dropdown-menu__item {
    background: none;
    color: #333;
    &:hover {
      background: none;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      color: #333;
      &:hover,
      &:focus {
        color: #70b4fa;
      }
    }
  }
}
</style>
